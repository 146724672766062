<template>
  <div class="enableContainer">
    <a-spin :spinning="spinning">
    <div class="enableBox">
      <div class="main">
        <a-button type="primary" @click="addOpen">新增</a-button>
        <a-table
            :columns="tableColumns"
            :data-source="tableData"
            :pagination="false"
            style="width: 99%;margin-top: 15px"
            :rowKey='record=>record.id'
        >
          <!--          图标-->
          <span slot="icon" slot-scope="text,item">
            <img :src="item.icon" alt="" v-if="item.icon" style="height: 50px;width: 50px">
          </span>
          <!--          状态-->
          <span slot="status" slot-scope="text,item">
            <a-switch checked-children="已发布" un-checked-children="未发布" v-model="item.is_open === 1 ? true : false" @click="tableStatusOpenDown(item)"/>
          </span>
          <!--          排序-->
          <template slot="sort" slot-scope="text,record,index">
            <span v-if="index === tableData.length - 1">
              <a style="margin-left: 10px" @click="rowUpDown(record.id, 'up')">上移</a>
            </span>
            <span v-if="index !== 0 && index !== tableData.length - 1">
              <a style="margin-left: 10px" @click="rowUpDown(record.id, 'up')">上移</a>
              <a style="margin-left: 10px" @click="rowUpDown(record.id, 'down')">下移</a>
            </span>
            <span v-if="index === 0">
              <a style="margin-left: 10px" @click="rowUpDown(record.id, 'down')">下移</a>
            </span>
          </template>
          <!--          操作-->
          <span slot="operation" slot-scope="text,record,index">
              <a style="margin-left: 10px" @click="editOpen(record)">修改</a>
              <a-popconfirm
                  title="确定要删除吗？"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="tableRemove(record)"
              >
              <a style="margin-left: 10px">删除</a>
              </a-popconfirm>
            </span>
        </a-table>
      </div>
      <!--------------------------------------对话框------------------------------------>
      <!--      新增-->
      <a-drawer
          title="新增"
          :width="480"
          :visible="addVisible"
          @close="addClose"
      >
        <a-form-model :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules" ref="addForm">
          <a-form-model-item  label="功能图标" prop="icon">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('addIcon')" ref="addIcon"/>
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="addForm.icon"
                    style="width: 95px;height:95px;"
                    :src="addForm.icon"
                />
              </div>
            </div>
            <span style="font-size: 14px">注：尺寸 150*150，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item label="功能名称" prop="title">
            <a-input v-model="addForm.title" placeholder="请输入" :maxLength="200"/>
          </a-form-model-item>
          <a-form-model-item label="功能链接" prop="link">
            <a-textarea v-model="addForm.link" placeholder="请输入" style="width: 100%;height: 80px"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-switch checked-children="开" un-checked-children="关" v-model="addForm.is_open === 1 ? true : false" @change="addStatus"/>
          </a-form-model-item>
        </a-form-model>
        <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button @click="addClose">
            取消
          </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>

      <!--      修改-->
      <a-drawer
          title="修改"
          :width="480"
          :visible="editVisible"
          @close="editClose"
      >
        <a-form-model :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="addFormRules" ref="editForm">
          <a-form-model-item  label="功能图标" prop="icon">
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;width:80%;margin-bottom: -10px">
              <div class="uploadImg">
                <input style="cursor:pointer;width: 100px;height: 100px;opacity: 0;" accept="image/*" type="file" @change="uploadPic('editIcon')" ref="editIcon"/>
              </div>
              <div class="upload_image">
                <img
                    alt=""
                    v-if="editForm.icon"
                    style="width: 95px;height:95px;"
                    :src="editForm.icon"
                />
              </div>
            </div>
            <span style="font-size: 14px">注：尺寸 150*150，大小不超过500kb</span>
          </a-form-model-item>
          <a-form-model-item label="功能名称" prop="title">
            <a-input v-model="editForm.title" placeholder="请输入" :maxLength="200"/>
          </a-form-model-item>
          <a-form-model-item label="功能链接" prop="link">
            <a-textarea v-model="editForm.link" placeholder="请输入" style="width: 100%;height: 80px"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="状态">
            <a-switch checked-children="开" un-checked-children="关" v-model="editForm.is_open === 1 ? true : false" @change="editStatus"/>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          <a-button @click="editClose">
            取消
          </a-button>
          <a-button style="margin-left: 15px" type="primary" @click="editSubmit">
            确定
          </a-button>
        </div>
      </a-drawer>
    </div>
    </a-spin>
  </div>

</template>

<script>
import {
  getCommunity,
  postCommunityAdd,
  postCommunityUpDown,
  putCommunityUpDelete,
} from "@/service/systemApi";
import {update} from "@/utils/update";

export default {
  name: 'CommunityEnableFunction',
  data() {
    return {
      tableColumns: [
        {
          title: '图标',
          dataIndex: 'icon',
          scopedSlots: {customRender: 'icon'}
        },
        {
          title: '功能名称',
          width: '20%',
          dataIndex: 'title'
        },
        {
          title: '链接',
          dataIndex: 'link',
          width: '30%'
        },
        {
          title: '发布时间',
          dataIndex: 'created_time',
          width: '15%'
        },
        {
          title: '状态',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '排序',
          scopedSlots: {customRender: 'sort'}
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operation'}
        }
      ],
      tableData: [],
      addVisible: false,
      editVisible: false,
      addForm: {
        is_open: 1,
        icon: ''
      },
      editForm: {},
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      addFormRules: {
        icon: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        link: [{ required: true, message: '请选择类型', trigger: 'blur' }]
      },
      spinning: false
    }
  },
  created() {
    this.getCommunityData()
  },
  methods: {
    addOpen () {
      this.addVisible = true
    },
    async editOpen (row) { // 修改打开
      let data = {
        "id": row.id
      }
      const response = await getCommunity(data)
      if (response.code === 0) {
        this.editForm = response.data[0]
        this.editVisible = true
      }else {
        this.$message.warning(response.message)
      }
    },
    addStatus () { // 新增页面中的状态按钮打开关闭方法
      this.addForm.is_open === 1 ? this.addForm.is_open = 0 : this.addForm.is_open = 1
    },
    async addSubmit () { // 新增提交
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          let data = this.addForm
          const response = await postCommunityAdd(data)
          if (response.code === 0) {
            await this.getCommunityData()
            this.$message.success('新增成功')
            this.addVisible = false
            this.$refs.addIcon.value = null
            this.addForm = {
              is_open: 1,
              icon: ''
            }
          }else {
            this.$message.warning(response.message)
          }
        }else {
          this.$message.error("请填写全部表单")
        }
      })
    },
    addClose () {
      this.addVisible = false
    },
    editClose () {
      this.editVisible = false
      this.$refs.editIcon.value = null
    },
    editStatus () { // 新增页面中的状态按钮打开关闭方法
      this.editForm.is_open === 1 ? this.editForm.is_open = 0 : this.editForm.is_open = 1
    },
    async getCommunityData (data) {
      this.spinning = true
      const response = await getCommunity(data)
      if (response.code === 0) {
        this.tableData = response.data
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async uploadPic(type){ // 图片上传方法
      let file = this.$refs[type].files[0];
      if (file !== undefined) {
        if (file.size < 512000){
          const image = await update(file)
          if(image.code === 0){
            if ( type === "addIcon" ) {
              this.addForm.icon = image.data.url
            }else {
              this.editForm.icon = image.data.url
            }
            this.$message.success("图片上传成功！")
          }else {
            this.$message.error(image.message)
          }
        }else {
          this.$message.error("请上传小于500kb的图片")
        }
      }
    },
    async rowUpDown (id, type) { // table 列表中的上移下移方法
      this.spinning = true
      let data = {
        "moveUpDown": type
      }
      const response = await postCommunityUpDown(id, data)
      if (response.code === 0) {
        await this.getCommunityData()
        this.$message.success("设置成功")
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    async editSubmit () { // 修改提交
      this.$refs.editForm.validate(async valid =>{
        if (valid) {
          this.spinning = true
          let data = this.editForm
          const response = await putCommunityUpDelete(data.id, 'set', data)
          if (response.code === 0) {
            await this.getCommunityData()
            this.$message.success("修改成功")
            this.editVisible = false
            this.$refs.editIcon.value = null
          }else {
            this.$message.warning(response.message)
          }
          this.spinning = false
        }else {
          this.$message.error("请填写全部表单")
        }
      })
    },
    async tableRemove (row) { // table 列表删除方法
      const response = await putCommunityUpDelete(row.id, 'delete', {})
      if (response.code === 0) {
        this.$message.success("删除成功")
        await this.getCommunityData()
      }else {
        this.$message.warning(response.message)
      }
    },
    async tableStatusOpenDown (row) { // table 列表中点击按钮直接进行状态修改
      this.spinning = true
      let data = {
        "is_open": row.is_open === 1 ? 0 : 1
      }
      const response = await putCommunityUpDelete(row.id, 'set', data)
      if (response.code === 0) {
        this.$message.success("修改成功")
        await this.getCommunityData()
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    }
  }
}
</script>

<style lang="scss" scoped>
.enableContainer {
  margin: -15px;

  .enableBox {
    display: flex;

    .menu {
      width: 12%;
      border-right: 10px solid rgba(240, 242, 245, 1);
    }

    .main {
      padding: 15px;
      width: 100%;
    }
  }

}

.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_image{
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
}
</style>